$text_color: #000;
// $link_color: #1E99D5;
$link_color: #990000;
$bg_gray: #F6F6F6;
$bg_gray_hover: #E9E9E9;
$border_gray: #CFCFCF;
$alert_green: #155724;
$alert_green_bg: #d4edda;
$alert_blue: #004085;
$alert_blue_bg: #cce5ff;
$alert_yellow: #856404;
$alert_yellow_bg: #fff3cd;
$alert_orange: #9e5000;
$alert_orange_bg: #ffdebc;
$alert_red: #721c24;
$alert_red_bg: #f8d7da;

// ブレイクポイント
$breakpoints: (
  'max_mob': 'screen and (max-width: 429px)',
  'min_tab': 'screen and (min-width: 430px)',
  'max_mob_l': 'screen and (max-width: 679px)',
  'min_tab_l': 'screen and (min-width: 680px)',
  'max_tab': 'screen and (max-width: 799px)',
  'min_s': 'screen and (min-width: 800px)',
  'max_s': 'screen and (max-width: 1039px)',
  'min_m': 'screen and (min-width: 1040px)',
  'max_m': 'screen and (max-width: 1279px)',
  'min_l': 'screen and (min-width: 1280px)',
  'max_l': 'screen and (max-width: 1499px)',
  'min_2l': 'screen and (min-width: 1500px)',
  'max_2l': 'screen and (max-width: 1679px)',
  'min_3l': 'screen and (min-width: 1680px)',
  'min_600': 'screen and (min-width: 600px)'
) !default;
@mixin mq($breakpoint: min_tab) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// 本文セクションの左右マージン
$cont_margin: 14px; // PC
$cont_margin_mob: 14px; // スマホ（必要なら変える）

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
  overflow-y: scroll;
  font-size: 62.5%;
}
body {
  margin: 0;
  padding: 0;
  // font-family: Arial, Roboto, "Droid Sans", 游ゴシック, YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  // font-family:
  //   -apple-system, BlinkMacSystemFont,
  //   Arial,
  //   "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3,
  //   "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN",
  //   "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",
  //   "游ゴシック体", YuGothic,
  //   "Yu Gothic M",
  //   "游ゴシック Medium", "Yu Gothic Medium",
  //   "メイリオ", Meiryo,
  //   Osaka,
  //   "ＭＳ Ｐゴシック", "MS PGothic",
  //   "Helvetica Neue", HelveticaNeue,
  //   Helvetica,
  //   Arial,
  //   "Segoe UI",
  //   sans-serif,
  //   "Apple Color Emoji",
  //   "Segoe UI Emoji",
  //   "Segoe UI Symbol",
  //   "Noto Color Emoji";

  // font-family:
  //   -apple-system, BlinkMacSystemFont,
  //   Arial,
  //   "游ゴシック体", YuGothic,
  //   "Yu Gothic M",
  //   "游ゴシック Medium", "Yu Gothic Medium",
  //   "メイリオ", Meiryo,
  //   Osaka,
  //   "ＭＳ Ｐゴシック", "MS PGothic",
  //   "Helvetica Neue", HelveticaNeue,
  //   Helvetica,
  //   Arial,
  //   "Segoe UI",
  //   sans-serif,
  //   "Apple Color Emoji",
  //   "Segoe UI Emoji",
  //   "Segoe UI Symbol",
  //   "Noto Color Emoji";

  font-family:
    // -apple-system, BlinkMacSystemFont,
    'Arial',
    // "游ゴシック体", YuGothic,
    // "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic",
    'ヒラギノ角ゴ ProN',
    'Hiragino Kaku Gothic ProN',
    'メイリオ',
    Meiryo,
    'ＭＳ Ｐゴシック',
    'MS PGothic',
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  // font-family: -apple-system, "Segoe UI", "Helvetica Neue", "Hiragino Kaku Gothic ProN", メイリオ, meiryo, sans-serif;
  font-weight: 500;
  font-size: 1.6em;
  height: 100%;
}

.serif {
  font-family:
    游明朝,
    "Yu Mincho",
    YuMincho,
    "Hiragino Mincho ProN",
    "Hiragino Mincho Pro",
    HGS明朝E,
    メイリオ,
    Meiryo,
    serif;
}



h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin: 3.5rem 0 2.5rem;
  font-feature-settings: "palt";
}

h1 {
  font-size: 2.6rem;
  @include mq(min_tab_l) {
    font-size: 3.2rem;
  }
}
h2 {
  font-size: 2.2rem;
  @include mq(min_tab_l) {
    font-size: 2.8rem;
  }
}
h3 {
  font-size: 2.0rem;
  @include mq(min_tab_l) {
    font-size: 2.4rem;
  }
}
h4 {
  font-size: 1.8rem;
  @include mq(min_tab_l) {
    font-size: 2.2rem;
  }
}
h5 {
  font-size: 1.8rem;
  @include mq(min_tab_l) {
    font-size: 2.0rem;
  }
}
h6 {
  font-size: 1.8rem;
}

p {
  font-size: 1.6rem;
  margin: 1.5rem 0;
}

// リンクとテキストリンク型のボタン
a,
input[type=button].btn-txt,
input[type=submit].btn-txt {
  color: $link_color;
  text-decoration: none;
  @include mq(min_m) { // PC
    &:hover {
      text-decoration: underline;
    }
  }
}

/**
 *
 * オーバーレイボタンとCSSアイコン
 *
 **/
// オーバーレイボタン
.overlayBtn {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  background-color: rgba(0,0,0,.5);
  cursor: pointer;
  opacity: .7;
  &:hover {
    opacity: 1;
  }
}
// ばってんアイコン
.icon-remove {
  text-indent: -9999em;
  position: absolute;
  top: 50%;
  left: 50%;
  &:before,
  &:after  {
    content: '';
    position: absolute;
    top: -0.2rem;
    left: -1.2rem;
    width: 2.4rem;
    height: .4rem;
    background-color: #fff;
    pointer-events: none;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
// 画像回転アイコン
.icon-rotate {
  text-indent: -9999em;
  position: absolute;
  top: 1.6rem;
  left: 1.4rem;
  pointer-events: none;
  width: 2.2rem;
  height: 1.8rem;
  border-radius: .3rem;
  background-color: #fff;
  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: -0.6rem;
    right: -0.7rem;
    display: block;
    border-radius: 1.5rem;
    width: 2.2rem;
    height: 2.2rem;
    border-top: .3rem solid #fff;
    border-right: .3rem solid transparent;
    border-bottom: .3rem solid transparent;
    border-left: .3rem solid transparent;
    transform: rotate(30deg);
    }
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: .2rem;
    right: -1rem;
    width: 0;
    height: 0;
    border-top: .8rem solid #fff;
    border-right: .4rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-left: .4rem solid transparent;
    transform: rotate(-10deg);
  }
}

ul {
  margin: 1.5rem 0;
  // padding: 0;
  padding-left: 1.2em;
}
li {
  font-size: 1.6rem;
  margin: .5rem 0;
  line-height: 1.6;
}
dl {
  margin: 1.5rem 0;
}
dt {
  margin: 1.5rem 0;
  font-size: 1.6rem;
}
dd {
  margin: 0 0 0 .5em;
  padding: 0;
  line-height: 1.6;
}

.table {
  border-top: 1px solid $border_gray;
  border-right: 1px solid $border_gray;
  th, td {
    padding: 5px 13px;
    border-left: 1px solid $border_gray;
    border-bottom: 1px solid $border_gray;
  }
  thead {
    th, td {
      background-color: $bg_gray;
    }
  }
}

// アラート
.alert_box {
  width: calc( 100% + 40px );
  margin: 0 -20px;
  padding: 10px 15px;
  border-left: 5px solid $border_gray;
  border-bottom: 1px solid $border_gray;
  background-color: $bg_gray;
  &.alert_green {
    border-left: 5px solid $alert_green;
    background-color: $alert_green_bg;
    color: $alert_green;
  }
  &.alert_blue {
    border-left: 5px solid $alert_blue;
    background-color: $alert_blue_bg;
    color: $alert_blue;
  }
  &.alert_yellow {
    border-left: 5px solid $alert_yellow;
    background-color: $alert_yellow_bg;
    color: $alert_yellow;
  }
  &.alert_orange {
    border-left: 5px solid $alert_orange;
    background-color: $alert_orange_bg;
    color: $alert_orange;
  }
  &.alert_red {
    border-left: 5px solid $alert_red;
    background-color: $alert_red_bg;
    color: $alert_red;
  }
}

// 仮
body {
  // background-color: #ece0bb;
  height: 100%;
}
#kd {
  margin: 0;
  width: 100%;
  @include mq(min_m) { // PC
    display: flex;
  }
}

// レスポンシブメニュー設定
#menu_toggle,
#hamburger_btn,
#menu_open_overlay {
  display: none;
}
@include mq(max_s) { // PC
  #menu_open_overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
    transition: margin .2s, opacity .2s, width 0s linear .2s;
    opacity: 0;
    z-index: 200;
    // display: none;
  }
  #kd,
  #header,
  #sidebar {
    transition: margin .2s;
  }
  #menu_toggle:checked + #kd,
  #menu_toggle:checked + #kd #header,
  #menu_toggle:checked + #kd #sidebar {
    margin-left: -280px;
  }
  #menu_toggle:checked + #kd #menu_open_overlay {
    // display: block;
    transition: margin .2s, opacity .2s;
    width: 100%;
    opacity: 1;
  }
  #hamburger_btn {
    position: absolute;
    top: 50%;
    margin-top: -25px; // 「MENU」テキストなし
    // margin-top: -20px; // 「MENU」テキストあり
    right: 5px;
    z-index: 201;
    cursor: pointer;
    display: block;
    width: 50px;
    height: 50px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  #hamburger_btn:active {
    -webkit-tap-highlight-color: transparent;
  }
  #hamburger_btn .hamburger_icon,
  #hamburger_btn .hamburger_icon:before,
  #hamburger_btn .hamburger_icon:after {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    height: 2px;
    margin-top: -1px;
    width: 30px;
    background: #000;
    content: '';
    transition: all 200ms ease-in-out;
  }
  #hamburger_btn .hamburger_text {
    position: relative;
    display: none; // 「MENU」テキストなし
    // display: block; // 「MENU」テキストあり
    top: 0;
    height: 14px;
    font-size: 12px;
    text-align: center;
  }
  #hamburger_btn .hamburger_text:before {
    position: absolute;
    width: 100%;
    left: 0;
    content: '';
    transition: all 200ms ease-in-out;
    visibility: visible;
    opacity: 1;
  }
  #hamburger_btn .hamburger_text:after {
    position: absolute;
    width: 100%;
    left: 0;
    content: '';
    transition: all 200ms ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  #hamburger_btn .hamburger_icon {
    transform: translate3d(-50%, 0, 0);
  }
  #hamburger_btn .hamburger_icon:before {
    transform: translate3d(-50%, -9px, 0);
  }
  #hamburger_btn .hamburger_icon:after {
    transform: translate3d(-50%, 6px, 0);
  }
  #menu_toggle:checked + #kd #hamburger_btn {
    // position: fixed;
    z-index: 201;
    top: 40px;
    @media screen and (max-width: 340px) {
      right: calc( ( 100vw - 280px - 50px ) / 2 );
    }
    // right: -6px;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_icon {
    background-color: transparent;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_icon:before,
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_icon:after {
    top: 0;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_icon:before {
    transform: translateX(-50%) rotate(45deg);
    top: 0;
    background-color: #fff;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_icon:after {
    transform: translateX(-50%) translateY(-10px) rotate(-45deg);
    top: 9px;
    background-color: #fff;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_text {
    top: -3px;
    color: #fff;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_text:before {
    visibility: hidden;
    opacity: 0;
  }
  #menu_toggle:checked + #kd #hamburger_btn .hamburger_text:after {
    visibility: visible;
    opacity: 1;
  }
}

// // header
// #header {
//   position: fixed;
//   z-index: 100;
//   top: 0;
//   left: 0;
//   width: 100%;
//   padding: 12px 15px 10px;
//   background-color: rgba(255,255,255,.6);
//   // background-color: rgba(250, 245, 235, .8);
//   border-bottom: 1px solid #ccc;
//   -webkit-backdrop-filter: saturate(.8) blur(4px);
//   backdrop-filter: saturate(.8) blur(4px);
//   @include mq(min_m) { // PC
//     width: calc( 100% - 280px );
//   }
//   @include mq(min_3l) { // 大大画面PC
//     // width: calc( 100vw - 1280px );
//     height: 100vh;
//     background-color: #EEE8D8;
//     background-color: rgba(250, 245, 235, 1);
//     // width: calc( ( 100vw - 1680px ) * 0.15 + 400px );
//     width: calc( ( 100vw - 1600px ) * 0.15 + 320px );
//     border-right: 1px solid #ccc;
//     border-bottom: none;
//   }
//   #site_title {
//     margin: 0;
//     width: 50%;
//     min-width: 200px;
//     max-width: 300px;
//     img {
//       width: 100%;
//       // filter: drop-shadow(0 0 6px #fff);
//     }
//     @include mq(min_3l) { // 大大画面PC
//       width: 100%;
//       max-width: unset;
//       text-align: right;
//       padding: 0 1rem;
//       img {
//         max-width: 350px;
//       }
//     }
//   }
// }
// header
#header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 8px 15px 0;
  // transition: height .2s,padding .2s;
  background-color: rgba(255,255,255,.6);
  // background-color: rgba(250, 245, 235, .8);
  border-bottom: 1px solid #ccc;
  -webkit-backdrop-filter: saturate(.8) blur(4px);
  backdrop-filter: saturate(.8) blur(4px);
  @include mq(min_tab) { // タブレット
    height: 60px;
  }
  @include mq(min_s) { // スマホ
    padding: 12px 15px 0;
    height: 75px;
  }
  @include mq(min_3l) { // 大大画面PC
    // width: calc( 100vw - 1280px );
    height: 100vh;
    background-color: #EEE8D8;
    background-color: rgba(250, 245, 235, 1);
    // width: calc( ( 100vw - 1680px ) * 0.15 + 400px );
    width: calc( ( 100vw - 1600px ) * 0.15 + 320px );
    border-right: 1px solid #ccc;
    border-bottom: none;
  }
  #site_title {
    margin: 0;
    width: 195px;
    // transition: width .2s;
    img {
      width: 100%;
    }
    @include mq(min_tab) { // タブレット
      width: 250px;
    }
    @include mq(min_s) { // PC小
      width: 300px;
    }
    @include mq(min_3l) { // 大大画面PC
      width: 100%;
      max-width: unset;
      text-align: right;
      padding: 0 1rem;
      img {
        max-width: 350px;
      }
    }
  }
}
body.dev #header {
  background-color: rgba(130,0,0,.6);
}

// サイドバー
#sidebar {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 100%;
  width: 280px;
  height: 100%;
  border-left: 1px solid #444;
  @include mq(max_s) { // PC
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  background-color: #000;
  color: #fff;
  @include mq(min_m) { // PC
    position: relative;
    left: auto;
    height: auto;
    .inner {
      position: sticky;
      top: 0;
      min-height: 100vh;
      max-width: 280px;
    }
  }
  @include mq(min_2l) { // 大画面PC
    width: 280px;
  }
  @include mq(min_3l) { // 大画面PC
    width: calc( ( 100vw - 1600px ) * 0.15 + 280px );
  }
}
// サイドバー白バージョン
#sidebar {
  // border-left: 1px solid #444;
  border-left: 1px solid #ccc;
  // background-color: #000;
  background-color: #fff;
  // color: #fff;
  color: #000;
}


// ユーザーブロック
#block_user {
  margin: 0;
  padding: 15px 15px 0;
  display: flex;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
  }
  .user_disp {
    width: calc( 100% - 50px );
    overflow: hidden;
    margin-left: 7px;
    .display_name {
      margin: 0;
      padding: .7rem 0 0;
      width: 100%;
      font-size: 1.6rem;
    }
    .user_nav {
      list-style-type: none;
      margin: 0;
      padding: .8rem 0 0;
      width: 100%;
      display: flex;
      li {
        margin: 0;
        padding: 0 1rem 0 0;
        font-size: 1.3rem;
        line-height: 1.15;
        &:not(:first-child) {
          border-left: 1px solid #ccc;
          padding-left: 1rem;
        }
      }
      a,
      .pure-form input[type=button].btn-txt,
      .pure-form input[type=submit].btn-txt {
        display: block;
        color: #ccc;
        padding: 0;
        @include mq(min_m) { // モバイル
          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
// サイドバー白バージョン
#block_user {
  .avatar {
    border: 1px solid #eee;
  }
  .user_disp {
    .user_nav {
      li {
        &:not(:first-child) {
          border-left: 1px solid #666;
        }
      }
      a,
      .pure-form input[type=button].btn-txt,
      .pure-form input[type=submit].btn-txt {
        color: #666;
        @include mq(min_m) { // モバイル
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}

// グローバルナビ
#gnav {
  // position: sticky;
  // top: 0;
  margin-top: 3rem;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      font-size: 2.0rem;
      font-weight: bold;
      line-height: 1.15;
    }
    a {
      display: block;
      padding: 1rem 20px 1rem 25px;
      // color: #fff; // サイドバー黒バージョン
      color: #000; // サイドバー白バージョン
      @include mq(min_m) { // モバイル
        &:hover {
          // background-color: #333; // サイドバー黒バージョン
          background: #F9F8F7; // サイドバー白バージョン
          color: $link_color; // サイドバー白バージョン
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// カレント項目
body#special #gnav li.special a,
body#article #gnav li.report a,
body#event #gnav li.event a,
body#spot #gnav li.spot a,
body#plan #gnav li.plan a,
body#photo #gnav li.photo a {
  // background-color: #333; // サイドバー黒バージョン
  background: #eee; // サイドバー白バージョン
}

// SNSアイコン
#sns_nav {
  margin-top: 3rem;
  ul {
    list-style-type: none;
    padding: 0 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    li {
      line-height: 1.15;
    }
    a {
      display: block;
      width: 60px;
      color: #fff;
      opacity: .8;
      @include mq(min_m) { // モバイル
        &:hover {
          opacity: 1;
        }
      }
      &:hover {
        text-decoration: none;
      }
      span {
        display: block;
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }
}
// サイドバー白バージョン
#sns_nav {
  ul {
    a {
      color: #333;
    }
  }
}

#content .cont_btm_note p {
  font-size: 1.4rem;
  line-height: 1.2;
}

// Adsense
#content .gads {
  text-align: center;
  margin: 3rem auto;
}

// 自前広告
.ad_side{
  margin: 3rem 0 0 25px;
  .ad {
    width: 230px;
    margin: 0 0 10px 0;
    position: relative;
    .icon_pr {
      position: absolute;
      top: .1rem;
      right: .1rem;
      background: rgba(#fff,.6);
      font-size: 1rem;
      // border:1px solid #000;
      border-radius: .3rem;
      color: #000;
      line-height: 1;
      padding: .1rem .2rem;
      z-index: 1;
    }
  }
  #ad-A {

  }
}
.add_cont{
  margin: 3rem 0 0 0;
  .ad {
    max-width: 700px;
    margin: 0 auto 10px auto;
    position: relative;
    .icon_pr {
      position: absolute;
      top: .1rem;
      right: .1rem;
      background: rgba(#fff,.6);
      font-size: 1rem;
      // border:1px solid #000;
      border-radius: .3rem;
      color: #000;
      line-height: 1;
      padding: .1rem .2rem;
      z-index: 1;
    }
  }
  #ad-S {

  }
}

// サブナビ
#snav {
  margin-top: 3rem;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 0;
      padding: .7rem 20px .7rem 25px;
      font-size: 1.3rem;
      line-height: 1.15;
    }
    a {
      // display: block;
      // margin: .5rem 20px .5rem 25px;
      color: #ccc;
      @include mq(min_m) { // モバイル
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
}
// サイドバー白バージョン
#snav {
  ul {
    a {
      color: #999;
      @include mq(min_m) { // モバイル
        &:hover {
          color: #000;
        }
      }
    }
  }
}

// フッター
#footer {
  height: 2rem;
  #copyright {
    @include mq(min_m) { // PC
      position: absolute;
      bottom: 0;
    }
    padding: 0 20px .5rem;
    font-size: 1.6rem;
    color: #ccc;
    margin: 0;
  }
}
// サイドバー白バージョン
#footer {
  #copyright {
    color: #999;
  }
}

// 共通部品
// ユーザー表示
.user_account {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .account {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 1rem;
    min-width: 0;
    flex-grow: 1;
    .user_icon {
      flex-shrink: 0;
      img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;
        border: 1px solid #ccc;
      }
    }
    .names {
      // flex-basis: 0;
      flex-shrink: 1;
      max-width: calc(100% - 60px);
      width: auto;
      padding: 0 0 0 1rem;
      .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.8rem;
        margin-bottom: .3rem;
        min-width: 0;
      }
      .user_name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.4rem;
        color: #666;
      }
    }
  }
  a.account {
    color: #000;
  }
  a:hover.account {
    text-decoration: none;
    .name {
      text-decoration: underline;
    }
  }
  .btns {
    flex-shrink: 0;
    padding: 0;
  }
}
.user_account_s {
  justify-content: flex-start;
  .account {
    margin-right: 1.5rem;
    .user_icon {
      img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
    }
    .names {
      padding: 0 0 0 1rem;
      display: flex;
      align-items: baseline;
      max-width: calc(100% - 50px);
      .name {
        // font-size: 1.6rem;
        margin-bottom: 0;
        margin: 0 .5rem 0 0;
        flex-shrink: 0;
        min-width: 0;
        max-width: 100%;
      }
      .user_name {
        min-width: 0;
        flex-shrink: 1;
      }
    }
  }
  .btns {
    .btn {
      padding: 0.3em .7em;
      // font-size: 90%;
    }
  }
}

// メインコンテンツ
#content {
  position: relative;
  width: 100%;
  padding: 0 0 10px;
  padding-top: 50px;
  // overflow-X: hidden;
  overflow: hidden; // 写真アップロード時に縦スクロールバーが出るので応急処置
  .content_fix_space {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 50px;
    .admin_bar {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .view_counter_block {
        .view_counter {
          background-color: rgba(0, 0, 0, .7);
          color: #fff;
          font-size: 1.4rem;
          border-radius: 1rem;
          padding: .2rem 1rem 0;
          span {
            font-weight: bold;
          }
        }
      }
      .recommend_btn_block {
        width: 4.3rem;
        height: 4.3rem;
      }
    }
  }
  @include mq(min_tab) { // タブレット
    padding-top: 60px;
    .content_fix_space {
      top: 60px;
    }
  }
  @include mq(min_s) { // スマホ
    padding-top: 75px;
    .content_fix_space {
      top: 75px;
    }
  }
  @include mq(min_m) { // PC
    width: calc( 100% - 280px );
  }
  @include mq(min_3l) { // 大大画面PC
    padding-top: 0;
    width: calc( ( 100vw - 1600px ) * 0.7 + 1000px );
    margin-left: calc( ( 100vw - 1600px ) * 0.15 + 320px );
    .content_fix_space {
      top: 0;
    }
  }
  .cont_header {
    padding: 0;
    .page_title {
      padding: 3rem 2rem;
      // margin-bottom: 3rem;
      margin: 2rem 0 0 0;
      text-align: center;
      font-family:
        游明朝,
        "Yu Mincho",
        YuMincho,
        "Hiragino Mincho ProN",
        "Hiragino Mincho Pro",
        HGS明朝E,
        メイリオ,
        Meiryo,
        serif;
      font-size: 3rem;
      @include mq(min_tab_l) {
        font-size: 4rem;
      }
    }
  }
  // h2とかh3で左右マージンがゼロのセクション内でヘッダーの左右にマージンが欲しい場合用
  .margin_lr {
    margin-left: 14px;
    margin-right: 14px;
  }
  // 主にh2,h3とかで前後の兼ね合いで上マージンを狭くしたい場合用
  .margin_top_min {
    margin-top: 8px!important;
  }
  // 主にh2,h3とかで前後の兼ね合いで下マージンを狭くしたい場合用
  .margin_bottom_min {
    margin-bottom: 5px!important;
  }
  img {
    max-width: 100%;
    .fit {
      width: 100%;
    }
  }
  p {
    line-height: 1.8;
  }
  .section {
    max-width: 800px;
    margin: 3rem auto 5rem;
    padding: 0;
  }
  .section-w {
    max-width: 100%;
    margin: 3rem auto 5rem;
    padding: 0;
  }
  .section-t {
    max-width: 550px;
    margin: 3rem auto 5rem;
    padding: 0;
  }
  .section,
  .section-w,
  .section-t {
    &> * {
      margin-left: $cont_margin;
      margin-right: $cont_margin;
    }
    &> img {
      margin-left: 0;
      margin-right: 0;
      padding-left: $cont_margin;
      padding-right: $cont_margin;
    }
  }

  .block-w {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .block-m {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
  .block-t {
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
  }
  .edit_buttons {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0;
    z-index: 150;
    display: flex;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.4);
    a {
      padding: 1rem;
      border-top: 1px solid #666;
      border-right: 1px solid #666;
      display: block;
      margin: 0;
      background-color: #fff;
      color: #000;
      &:hover {
        text-decoration: none;
        background-color: #990000;
        color: #fff;
      }
    }
  }
}
.body_elements {
  max-width: 800px;
  margin: 3rem auto 5rem;
  padding: 0;
  .element_image {
    margin: 1.5rem 0!important;
    @include mq(min_600) { // PC
      margin: 1.5rem $cont_margin!important;
    }
    display: block;
    background-color: #222;
    //↓テスト↓↓↓↓↓↓↓
    background-color: #efefef;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    //↑テスト↑↑↑↑↑↑↑
    position: relative;
    .photo_block {
      display: block;
      //↓テスト↓↓↓↓↓↓↓
      backdrop-filter: blur(20px);
      //↑テスト↑↑↑↑↑↑↑
      // padding: 1px;
      img {
        width: 100%;
        height: 100%;
        max-height: 65vh;
        //↓テスト↓↓↓↓↓↓↓
        max-height: 50vh;
        //↑テスト↑↑↑↑↑↑↑
        min-height: 150px;
        object-fit: contain;
        font-family: 'object-fit: contain;'; /*IE対策*/
        // object-position: bottom right;
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
      }
    }
    .photo_by {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #FFF;
      font-size: 1.2rem;
      text-shadow: 0px 0px 5px #000000, 0px 0px 2px #000000;
      z-index: 20;
      a {
        color: #FFF;
      }
    }
  }
  .caption {
    margin: -1rem .5rem 1.5rem;
    font-size: 1.4rem;
    // text-align: right;
  }
}


// メッセージ
#content .messages {
  padding: 1rem;
  border: 2px solid #ffaa00;
  background-color: #ffe0a1;
  .header {
    font-size: 2.2rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    line-height: 1.2;
    margin: 0.5rem 0 0 0;
  }
}

// メッセージブロック
#content .message_block {
  margin: 1.5rem 1.5rem 2rem;
  padding: 1rem 2rem;
  border: 2px solid #ccc;
  background-color: #fcfcfc;
}

// align:center レイアウトテスト
#content .style-test {
  // text-align: center;
  h2 {
    // border-top: 1px solid #cfcfcf;
    // padding-top: 1rem;
    // border-bottom: 1px solid #cfcfcf;
    // padding-bottom: .5rem;
  }
  .element {
    max-width: 100%;
    & > * {
      display: inline-block;
      text-align: left;
      list-style-type: none;
    }
    // & > p {
    //   max-width: 600px;
    // }
  }
  .element_image {
    background-color: #efefef;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .photo_block {
      backdrop-filter: blur(20px);
      // background-color: rgba(255,255,255,.3);
      // background-color: rgba(255,255,255,.4);
      // background-color: rgba(0,0,0,.6);
      img {
        max-height: 50vh;
      }
    }
  }
}

// // フォームのラベル（タイトル）に線（基本的にボツ）
// .pure-form-stacked .form_header {
//   display: flex;
//   flex-flow: row nowrap;
//   // justify-content: center;
//   align-items: center;
//   &:after {
//     content: '';
//     border-bottom: 1px solid #999;
//     flex-grow: 2;
//     margin-left: 1.7rem;
//   }
// }


// カテゴリーチェッカー他チェックボックス
.checkboxes {
  ul {
    margin: 0;
    width: 100%;
    padding-left: 0;
    padding: 0.5em 0.6em;
    margin: 0.25em 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  li {
    display: inline-block;
    margin: .1rem 1rem .1rem .5rem;
    .row {
      // display: flex;
      // flex-flow: row nowrap;
      // align-items: center;
      label {
        padding: 0.2rem .5rem .2rem .2rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &.parent {
      display: block;
      margin: .5rem .3rem .1rem;
      .row {
        display: block;
      }
    }
  }
}

// ログインページ
#login {
  .section-t {
    max-width: 570px;
    margin: 30px auto;
    padding: 30px;
    border: 1px solid #ccc;
    box-shadow:0px 0px 15px -8px rgba(0,0,0,0.5);
    input[type=color],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    .btn {
      max-width: 100%;
      @media screen and (min-width: 550px) {
        font-size: 2.2rem;
        font-weight: bold;
      }
    }
  }
}

// ユーザーページ
#user_show,
#user_edit {
  .cover {
    width: 100%;
    max-height: 300px;
    background-color: #000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &:before {
      content: '';
      width: 100%;
      height: 0;
      padding-top: 50%;
    }
    .coverImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; /*IE対策*/
    }
    // // IE11では画像がはみ出るのでIE11だけ以下を適用
    // _:-ms-lang(x)::-ms-backdrop, .coverImage {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   width: auto;
    //   height: auto;
    //   transform: translate3d(-50%, -50%, 0);
    //   min-width: 100%;
    //   min-height: 100%;
    // }
    .cover_image_edit {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .user_header {
    padding-top: 10px;
    margin-top: 0;
    .basic_profile {
      position: relative;
      .user_icon {
        position: absolute;
        top: -55px;
        left: -7px;
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50px;
        border:4px solid #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;'; /*IE対策*/
        }
      }
      .names {
        padding: 50px 0 0 0;
        min-height: 50px;
        .name {
          margin: 0;
          padding: 0 0 0 .5rem;
          font-size: 2rem;
        }
        .user_name {
          margin: -.3rem 0 0 .8rem;
          font-size: 1.4rem;
          color: #999;
        }
      }
      .edit_btn {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #eee;
        border:1px solid #ccc;
        padding: .2em .5em;
      }
    }
  }
  @include mq(min_600) {
    .cover {
      .imageFieldComponent {
        margin: 0;
        .cover_image_edit {
          top: initial;
          bottom: 10px;
        }
      }
    }
    .user_header {
      padding-top: 15px;
      .basic_profile {
        .user_icon {
          top: -45px;
        }
        .names {
          padding: 0 150px 0 100px;
          min-height: 60px;
          .name {
            padding: 0;
          }
          .user_name {
            margin: -.3rem 0 0 .2rem;
          }
        }
      }
    }
  }
  @include mq(min_s) { // スマホ
    .user_header {
      padding-top: 15px;
      .basic_profile {
        .user_icon {
          top: -80px;
          left: -10px;
          width: 150px;
          height: 150px;
          border-radius: 75px;
          background-color: #fff;
        }
        .names {
          padding: 0 150px 0  150px;
          min-height: 80px;
          .name {
            font-size: 2.4rem;
          }
          .user_name {
            margin: 0 0 0 .2rem;
          }
        }
      }
    }
  }
  .user_roles {
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    // margin: .7rem .5rem;
    padding-left: 0;
    list-style-type: none;
    li {
      padding: 0;
      margin: .3rem .2rem;
      border: 1px solid #aaa;
      border-radius: 30px;
      color: #000;
      background-color: #e6e6e6;
      text-decoration: none;
      font-size: 1.4rem;
      padding: 0 .8rem;
    }
  }
  .tab_radio {
    display: none;
  }
  .tab_index {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #ccc;
    .tab_item {
      // background-color: #FCC;
      flex-grow: 1;
      padding: 1rem .5rem;
      text-align: center;
      font-size: 2.0rem;
      cursor: pointer;
      .count {
        margin-left: .5rem;
        font-size: 1.6rem;
        color: #666;
      }
    }
  }
  #tab_photo:checked ~ .tab_index .tab_item_photo,
  #tab_article:checked ~ .tab_index .tab_item_article,
  .tab_index_photo .tab_item_photo,
  .tab_index_article  .tab_item_article {
    border-bottom: 2px solid #000;
  }
  .tab_index a {
    text-decoration: none;
    color: #000;
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
  .tab_radio ~ .tab_content {
    display: none;
  }
  #tab_photo:checked ~ .user_photos,
  #tab_article:checked ~ .user_articles {
    display: block;
  }
  .tab_content .message {
    width: 100%;
    text-align: center;
  }
}
#content .creatorRegistration {
  text-align: center;
  .note {
    text-align: left;
    margin-bottom: 1rem;
    .note_header{
      margin: 0;
      padding: 0;
    }
    .note_body {
      padding: .5rem 1rem;
      border: 1px solid #ccc;
      background-color: #fff;
      border-radius: 5px;
      p {
        line-height: 1.3;
        margin: 0;
        padding: 0;
      }
    }
  }
  form {
    text-align: left;
  }
  .form_buttons {
    margin: 0 auto;
    padding: 0;
    justify-content: center;
  }
}

.dialog,
.mordal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba(0,0,0,.2);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  &> .inner {
    background: #fff;
    border: 1px solid #ccc;
    // margin: 0 auto;
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 210;
    display: flex;
    // flex-flow: column wrap;
    // justify-content: center;
    // align-content: center;
    flex-direction: column;
    // width: calc(100vw - 10px);
    width: 100%;
    @include mq(min_m) { //
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.4);
      margin: 0 auto;
      // position: fixed;
      // top: 50%;
      // left: 50%;
      // transform: translate3d(-50%, -50%, 0) scale(1.0, 1.0);
      // backface-visibility: hidden;/* animation時のにじみ防止 */
      // -webkit-backface-visibility: hidden;/* animation時のにじみ防止 */
      // -webkit-font-smoothing: antialiased;/* animation時の文字にじみ防止 */
      // -moz-osx-font-smoothing: grayscale;/* animation時の文字にじみ防止 */
      // backface-visibility: hidden;
      // transform-style: preserve-3d;
      // -webkit-font-smoothing: subpixel-antialiased;
      // overflow-y: auto;
      width: calc(100vw - 60px);
      max-height: calc(100vh - 60px)!important;
      overflow: hidden;
    }
    // max-height: calc(100vh - 10px);
    .dialog-header,
    .mordal-header {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background-color: rgba(255,255,255,.5);
      border-bottom: 1px solid #ccc;
      // padding: .5rem .8rem;
      padding: .5rem;
      min-height: 5rem;
      @include mq(min_m) { //
        padding: 1rem;
      }
      .cancelBtn {
        order: 1;
        width: 4rem;
        height: 4rem;
        flex-basis: 4rem;
        flex-shrink: 1;
        min-width: 4rem;
        // background-color: #EEE;
        text-indent: -9999em;
        cursor: pointer;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          height: 2px;
          background: #666;
          transform: translateY(-1px) translateX(-12px) rotate(45deg);
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          height: 2px;
          background: #666;
          transform: translateY(-1px) translateX(-12px) rotate(-45deg);
        }
      }
      .dialog-title,
      .mordal-title {
        order: 2;
        flex-grow: 2;
        flex-shrink: 0;
        flex-basis: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        max-width: calc(100% - 4rem);
        .title {
          font-size: 1.8rem;
          @include mq(min_m) { //
            font-size: 2.4rem;
          }
          margin: 0;
          line-height: 1.2;
          max-width: 100%;
          overflow-y: hidden;
          // text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: .8rem;
        }
        .confirmBtn {
          order: 3;
          margin-right: .4rem;
        }
      }
    }
    .dialog-content,
    .mordal-content {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
      // padding: 30px 10px 50px;
      padding: 10px;
      background-color: #fff;
      // height: 100%;
      .dialog-loading {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }
      .buttons {
        // text-align: center;
        margin-top: 0px;
      }
      .section-w {
        margin: 0 auto;
      }
    }
  }
}
.dialog-m .inner {
  max-width: 800px;
  max-height: calc(100vh - 80px);
}
.dialog-t .inner {
  max-width: 510px;
  max-height: calc(100vh - 80px);
}

@include mq(max_s) { //
.mordal {
  .mordal-header {
    height: 51px;
  }
  .mordal-content {
    height: calc(100vh - 51px);
    .uploader {
      background-color: #eee;
      height: calc(100vh - 76px)!important;
      min-height: 200px;
      // max-height: 595px;
    }
    .imagePreview {
      padding: 0;
      border: none;
    }
  }
}
}
@include mq(min_m) { //
.mordal {
  // .mordal-content {
  //   max-height: 617px;
  // }
  .imageUploader {
    max-width: 617px;
    // height: 600px;
    // height: 100%;
    .uploader {
      height: calc(100vh - 144px)!important;
      min-height: 200px;
      max-height: 595px;
    }
  }
}
}
// ローディングアニメーション
.loader,
.loader:after {
  border-radius: 50%;
  display: inline-block;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 0 5px 5px 0;
  font-size: 5px;
  width: 30px;
  height: 30px;
  position: relative;
  text-indent: -9999em;
  overflow: hidden;
  border: 3px solid rgba(148, 176, 255, 0.2);
  border-left: 3px solid rgba(148, 176, 255, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-inline {
  display: flex;
  align-items: center;
  .icon {
    flex-shrink: 0;
    margin-right:.5rem;
  }
}

// ページネーションリンク
.pagination {
  margin: 2rem auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  list-style-type: none;
  .page-item {
    line-height: 1;
    margin: 0 2px 4px;
    .page-link {
      display: block;
      line-height: 1;
      font-size: 1.8rem;
      text-align: center;
      padding: 1.5rem 0;
      min-width: 5rem;
      background-color: #EEE;
      color: #000;
    }
    a.page-link {
      background-color: #000;
      color: #fff;
      &:hover {
        text-decoration: none;
        background-color: #900;
        color: #fff;
      }
    }
  }
  .active {
    .page-link {
      background-color: #900;
      color: #fff;
    }
  }
  .disabled {
    .page-link {
      background-color: #EEE;
      color: #000;
    }
  }
}

#content .top-section{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
// 一覧ページスライダー
.section,
.section-w,
.section-t {
  &.top-section {
    display: flex;
    flex-flow: row wrap;
  }
  &.index-slider > .l-inner {
    margin-left: 0!important;
    margin-right: 0!important;
  }
  &.index-slider > img {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
.top-slide .images {
  height: auto!important;
  min-height: auto!important;
  max-height: none!important;
}
.index-slider-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  // margin: 0 1rem 0 0;
  // margin-left: 0!important;
  width: 100%;
  .swiper-controller {
    display: flex;
    position: relative;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    align-items: center;
    .swiper-pagination {
      position: relative;
      align-items: center;
      display: flex;
      // bottom: 4px;
      left: 0;
      z-index: 99;
      opacity: 1;
      font-size: 0;
      .swiper-pagination-bullet {
        width: 25px!important;
        height: 5px!important;
        margin: 0 2px!important;
        // box-shadow: 0px 0px 7px rgba(0,0,0,.3);
        display: inline-block!important;
        background: #ccc!important;
        opacity: 1!important;
        line-height: 0!important;
        border-radius: 0!important;
      }
      .swiper-pagination-bullet-active {
        // background: #1E99D5!important;
        background: #990700!important;
      }
    }
    .swiper-button-prev {
      margin-right: 1rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      display: block;
      top: auto;
      margin-top: 0;
      transition: all 200ms ease-in-out;
      width: 30px;
      height: 35px;
      text-indent: -9999em;
      background: none!important;
      outline:0;
      border: 1px solid #ccc;
    }
    .swiper-button-prev {
      border-radius: 5px 0 0 5px;
    }
    .swiper-button-next {
      border-left: none;
      border-radius: 0 5px 5px 0;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      content: "";
      position: absolute;
      top: 11px;
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .swiper-button-prev::after {
      right: 7px;
      border-bottom: 2px solid #000;
      border-left: 2px solid #000;
    }
    .swiper-button-next::after {
      left: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      opacity: .6;
      // background: rgba(0,0,0,.9);
    }
  }
}

.index-slider {
  .node-list-item {
    flex-basis: 250px!important;
    height: auto;
    margin-bottom: 0!important;
  }
  // .title {
  //   font-size: 1.4rem!important;
  // }
  // .excerpt {
  //   display: none;
  // }
  // .swiper-slide {
  //   &:not(.swiper-slide-visible) {
  //     .slide {
  //       pointer-events: none;
  //       opacity: .3;
  //     }
  //   }
  // }
}

// 各コンテンツトップの検索（コンテンツ開閉部分も）
.content_search_form {
  padding: 0 15px 15px;
  // border: solid 4px #DFD9D0;
  position: relative;
  overflow: hidden;
  // background-color: #F9F8F7;
  color: #000;
  .search_form_detail {
    display: grid;
    grid-template-rows: 0fr;
    transition: 250ms grid-template-rows ease;
    &> div {
      overflow: hidden;
    }
  }
  .acd-check:checked + .acd-label + .acd-content{
    grid-template-rows: 1fr;
  }
}


// 写真ギャラリーシングル
#photo_flame {
  position: relative;
  .title_area {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 2rem;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7));
    .title {
      text-align: center;
      font-size: 18px;
      color: #fff;
      margin: 1rem 0;
    }
  }
  .img_block {
    background-color: #222;
    width: 100%;
    text-align: center;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      // background-color: rgba(100,0,100,.4);
    }
    .img {
      max-height: calc(100vh - 170px);
      // min-height: 400px;
      border: 1px solid #444;
    }
  }
}

#content .photo_dl_btn {
  margin: 2rem auto;
  text-align: center;
}
#content .photo_cont_header {
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  .caption {
    padding: 0 1rem;
    .title {
      font-size: 1.8rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  // .places,
  // .tags,
  // .categories,
  // .areas {
  //   display: flex;
  //   ul {
  //     margin-top: 0;
  //     padding-left: 0;
  //     list-style-type: none;
  //     display: flex;
  //     flex-flow: row wrap;
  //     justify-content: flex-start;
  //     align-items: center;
  //     li {
  //       // background-color: #eee;
  //       margin: .3rem;
  //       .name {
  //         padding: 0 .5rem;
  //       }
  //     }
  //   }
  // }
}
#content .photo_size_type {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  li {
    width: 30%;
    margin: 1%;
    // margin: 0;
    // background-color: #f6f6f6;
    .size {
      position: relative;
      padding-top: 6px;
      margin: 0;
      width: 100%;
      height: 48px;
      border-bottom: 1px solid #000;
      &::before, &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        background-color: #333;
        font-size: 1.3rem;
        font-weight: bold;
        color: #fff;
        text-align: center;
        vertical-align: middle;
      }
      &::after {
        top: 6px;
        left: 14px;
      }
      p {
        margin: 0;
        line-height: 1.2;
        span {
          display: block;
          font-size: 1.2rem;
        }
      }
    }
    .detail {
      margin-top: 7px;
      p {
        margin: 0;
        padding: 0 10px;
        // text-align: right;
      }
    }
  }
  .size_s .size {
    padding-left: 50px;
    &::before, &::after {
      width: 30px;
      height: 23px;
      top: 9px;
    }
    &::after {
      content: "S";
      padding-top: .1rem;
      top: 13px;
    }
  }
  .size_m .size {
    padding-left: 54px;
    &::before, &::after {
      width: 34px;
      height: 26px;
      top: 8px;
    }
    &::after {
      content: "M";
      padding-top: .25rem;
      top: 12px;
    }
  }
  .size_l .size {
    padding-left: 61px;
    &::before, &::after {
      width: 40px;
      height: 29px;
      top: 6px;
    }
    &::after {
      content: "L";
      padding-top: .4rem;
      top: 10px;
    }
  }
}
#content #photo_cont {
  .photo_details {
    border-top: 1px solid #ccc;
    padding: 1rem 1rem 0;
    margin: -2rem auto 0;
    .photo_detail {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      .photo_detail_model_release,
      .photo_detail_property_release {
        display: flex;
        align-items: center;
        margin-right: 2rem;
      }
    }
  }
  .photo_dl {
    margin: 3rem 0;
    padding: 2rem 0 0;
    border: solid 1px #DFD9D0;
    background-color: #F9F8F7;
    h2 {
      margin: 0 0 0 3rem;
    }
    .photo_size_type {
      .size {
        display: block;
        border: none;
        &:hover {
          text-decoration: none;
          color: #000;
          background-color: #DFD9D0;
        }
      }
    }
  }
  .cont_request {
    margin: 3rem 0;
    padding: 0 2rem 2rem;
    border: solid 1px #DFD9D0;
    background-color: #F9F8F7;
  }
}

// 写真ギャラリー一覧

.photo_thumbs {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 1px);
  // background: #990000;
  overflow: hidden;
  .item {
    // @include mq(min_m) { // PC
    // }
    // // max-width: calc(100% / 5);
    // flex-basis: calc(100% / 5.001 - 1px);
    margin: 0 0 1px 1px;
    // border-left: 1px solid #fff;
    // border-bottom: 1px solid #fff;
    height: auto;
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    &>.icon_pr {
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      background: rgba(#fff,.6);
      font-size: 1.2rem;
      border:1px solid #000;
      border-radius: .3rem;
      color: #000;
      line-height: 1;
      padding: .1rem .2rem;
      z-index: 1;
    }
    &>.link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before{
        content: "";
        height: 100%;
        vertical-align: middle;
        width: 0px;
        display: inline-block;
      }
      .title {
        display: none;
      }
      .img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border: 1px solid #444;
      }
      // IE11では画像がはみ出るのでIE11だけ以下を適用
      _:-ms-lang(x)::-ms-backdrop, .img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        transform: translate3d(-50%, -50%, 0);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .author-link {
      position: absolute;
      top: 0px;
      left: 0px;
      background: rgba(#000,0.8);
      font-size: 1.2rem;
      // display: none;
      .link {
        display: inline-block;
        padding: 3px 5px;
        background: none;
        color: #fff;
      }

    }
  }
  // 公開状態別設定
  .status-1 { // 公開
  }
  .status-5 { // 限定公開
  }
  .status-10 { // 予約
  }
  .status-20 { // 下書き
  }
  .status-40 { // 承認申請中
    .link {
      background-color: #ffaa00
    }
  }
  .status-45 { // 承認却下
    opacity: .3;
  }
  .status-60 { // 公開制限中
    opacity: .6;
    .link {
      background-color: #990000
    }
  }
  .status-90 { // 非公開
    opacity: .6;
    .link {
      background-color: #ff6666
    }
  }
}
#content {
  .section,
  .section-w,
  .section-t {
    .photo_thumbs {
      margin-left: -1px;
      margin-right: 0;
    }
  }
}
// 写真プレビュー 一覧のサイズ設定
// 幅800pxセクションの中の場合
.section {
  .photo_thumbs {
    .item {
      flex-basis: calc(100% / 3.001 - 1px);
      @include mq(min_600) {
        flex-basis: calc(100% / 4.001 - 1px);
      }
      // @include mq(min_s) {
      //   flex-basis: calc(100% / 5.001 - 1px);
      // }
    }
  }
}
// フル幅セクションの中の場合
.section-w {
  .photo_thumbs {
    .item {
      flex-basis: calc(100% / 3.001 - 1px);
      @include mq(min_600) {
        flex-basis: calc(100% / 4.001 - 1px);
      }
      @include mq(min_s) {
        flex-basis: calc(100% / 5.001 - 1px);
      }
      @include mq(min_m) {
        flex-basis: calc(100% / 4.001 - 1px);
      }
      @include mq(min_l) {
        flex-basis: calc(100% / 5.001 - 1px);
      }
      @include mq(min_2l) {
        flex-basis: calc(100% / 6.001 - 1px);
      }
      @include mq(min_3l) {
        flex-basis: calc(100% / 5.001 - 1px);
      }
      @media screen and (min-width: 1920px) {
        flex-basis: calc(100% / 6.001 - 1px);
      }
      @media screen and (min-width: 2200px) {
        flex-basis: calc(100% / 7.001 - 1px);
      }
      @media screen and (min-width: 2500px) {
        flex-basis: calc(100% / 8.001 - 1px);
      }
      @media screen and (min-width: 2800px) {
        flex-basis: calc(100% / 9.001 - 1px);
      }
    }
  }
}
// マルチプル画像プレビューラッパー
.multiple_images_preview {
  .item {
    .draggable-handle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
    }
    .icon_new {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      color: #fff;
      font-size: 1rem;
      padding: .2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background: rgba(255,0,0,.7);
    }
    .author {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;
      color: #fff;
      font-size: 1.1rem;
      max-width: 100%;
      // padding: 1.5rem .5rem .5rem;
      padding: .2rem .5rem;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      // background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7));
      background-color: rgba(#000,0.5);
    }
  }
  .sortable-chosen {
    border: 5px solid #900;
  }
  .sortable-ghost {
    opacity: .4;
  }
}
// 画像アップロードプレビュー
.imagePreview {
  .prvWrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    // background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // position: relative;
    .overlayBtn {
      position: absolute;
      z-index: 90;
    }
    .removeBtn {
      top: 10px;
      left: 10px;
    }
    .rotateBtn {
      top: 10px;
      right: 10px;
    }
    .details {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 10;
      font-size: 1.2rem;
      background-color: rgba(#fff, .4);
      padding: .3rem .5rem;
    }
    .prvImage {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .uploadImagePreview {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border: 1px solid #444;
      }
      // IE11では画像がはみ出るのでIE11だけ以下を適用
      _:-ms-lang(x)::-ms-backdrop, .uploadImagePreview {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        transform: translate3d(-50%, -50%, 0);
        max-width: 100%;
        max-height: 100%;
      }
    }
    @keyframes rotate0 {
      0%{ transform: rotate(270deg);}
      100%{ transform: rotate(360deg); }
    }
    @keyframes rotate1 {
      0%{ transform: rotate(0deg);}
      100%{ transform: rotate(90deg); }
    }
    @keyframes rotate2 {
      0%{ transform: rotate(90deg);}
      100%{ transform: rotate(180deg); }
    }
    @keyframes rotate3 {
      0%{ transform: rotate(180deg);}
      100%{ transform: rotate(270deg); }
    }
    .rotate-init-0 .uploadImagePreview { transform: rotate(0deg); }
    .rotate-init-0 .uploadImagePreview { transform: rotate(0deg); }
    .rotate-init-1 .uploadImagePreview { transform: rotate(90deg); }
    .rotate-init-2 .uploadImagePreview { transform: rotate(180deg); }
    .rotate-init-3 .uploadImagePreview { transform: rotate(270deg); }
    .rotate-0 .uploadImagePreview {
      animation: 0.2s ease-out rotate0;
    }
    .rotate-1 .uploadImagePreview {
      animation: 0.2s ease-out rotate1;
      transform: rotate(90deg);
    }
    .rotate-2 .uploadImagePreview {
      animation: 0.2s ease-out rotate2;
      transform: rotate(180deg);
    }
    .rotate-3 .uploadImagePreview {
      animation: 0.2s ease-out rotate3;
      transform: rotate(270deg);
    }
    // IE11用 ここから-----------------------------------------------------------
    // ※IE11はプレビュー画像をtranslateで動かしてるのでそれを入れないと回したらとんでいく。
    @keyframes rotate0-ie11 {
      0%{ transform: translate3d(-50%, -50%, 0) rotate(270deg);}
      100%{ transform: translate3d(-50%, -50%, 0) rotate(360deg); }
    }
    @keyframes rotate1-ie11 {
      0%{ transform: translate3d(-50%, -50%, 0) rotate(0deg);}
      100%{ transform: translate3d(-50%, -50%, 0) rotate(90deg); }
    }
    @keyframes rotate2-ie11 {
      0%{ transform: translate3d(-50%, -50%, 0) rotate(90deg);}
      100%{ transform: translate3d(-50%, -50%, 0) rotate(180deg); }
    }
    @keyframes rotate3-ie11 {
      0%{ transform: translate3d(-50%, -50%, 0) rotate(180deg);}
      100%{ transform: translate3d(-50%, -50%, 0) rotate(270deg); }
    }
    _:-ms-lang(x)::-ms-backdrop, .rotate-init-0 .uploadImagePreview { transform: translate3d(-50%, -50%, 0) rotate(0deg); }
    _:-ms-lang(x)::-ms-backdrop, .rotate-init-0 .uploadImagePreview { transform: translate3d(-50%, -50%, 0) rotate(0deg); }
    _:-ms-lang(x)::-ms-backdrop, .rotate-init-1 .uploadImagePreview { transform: translate3d(-50%, -50%, 0) rotate(90deg); }
    _:-ms-lang(x)::-ms-backdrop, .rotate-init-2 .uploadImagePreview { transform: translate3d(-50%, -50%, 0) rotate(180deg); }
    _:-ms-lang(x)::-ms-backdrop, .rotate-init-3 .uploadImagePreview { transform: translate3d(-50%, -50%, 0) rotate(270deg); }
    _:-ms-lang(x)::-ms-backdrop, .rotate-0 .uploadImagePreview {
      animation: 0.2s ease-out rotate0-ie11;
    }
    _:-ms-lang(x)::-ms-backdrop, .rotate-1 .uploadImagePreview {
      animation: 0.2s ease-out rotate1-ie11;
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
    _:-ms-lang(x)::-ms-backdrop, .rotate-2 .uploadImagePreview {
      animation: 0.2s ease-out rotate2-ie11;
      transform: translate3d(-50%, -50%, 0) rotate(180deg);
    }
    _:-ms-lang(x)::-ms-backdrop, .rotate-3 .uploadImagePreview {
      animation: 0.2s ease-out rotate3-ie11;
      transform: translate3d(-50%, -50%, 0) rotate(270deg);
    }
    // IE11用 ここまで-----------------------------------------------------------
  }
}
// マルチプル画像アップロードプレビュー
.multiplePreview {
  .prvWrap {
    background-color: #000;
    .overlayBtn {
      border-radius: 25px;
      width: 40px;
      height: 40px;
      background-color: rgba(0,0,0,.5);
      cursor: pointer;
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
    .removeBtn {
      top: 3px;
      left: 3px;
    }
    .rotateBtn {
      top: 3px;
      right: 3px;
      .icon-rotate {
        top: 1.1rem;
        left: .9rem;
      }
    }
    .details {
      display: none;
    }
  }
}
// シングル画像アップロードプレビュー
.singlePreview {
  // background-color: #F9F6F1;
  background-color: #222;
  padding: 0;
  border: 1px solid #ccc;
  margin: 0;
  max-width: 500px;
  position: relative;
  &:before{
    content: "";
    padding-top: 100%;
    vertical-align: middle;
    height: 0px;
    width: 0px;
    display: inline-block;
  }
  .prvWrap {
    padding: 10px;
    // .prvImage {
    //   .uploadImagePreview {
    //     border: 1px solid #000;
    //   }
    // }
  }
}

// 写真ギャラリー一覧（GooglePhoto風）
.photo_thumbs_pg {
  overflow: hidden;
  .item {
    float: left;
    margin: 1px;
    border: 1px solid #eee;
    box-sizing: content-box;
    overflow: hidden;
    position: relative;
    .title {
      display: none;
    }
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}
// // スクエア枠の中に縦横表示（グリッド）
// .photo_thumbs_sq {
//   /* グリッドコンテナ */
//   display: grid;
//   /* 最小100px、最大1frの列を繰り返しつくる */
//   grid-template-columns: repeat(
//     auto-fill,
//     minmax(110px, 1fr)
//   );
//   @include mq(min_tab) { // タブレット以上
//     /* 最小180px、最大1frの列を繰り返しつくる */
//     grid-template-columns: repeat(
//       auto-fill,
//       minmax(140px, 1fr)
//     );
//   }
//   @include mq(min_s) { // PC-s以上
//     /* 最小180px、最大1frの列を繰り返しつくる */
//     grid-template-columns: repeat(
//       auto-fill,
//       minmax(180px, 1fr)
//     );
//   }
//   column-gap: 1px;
//   row-gap: 1px;
//   margin-left: -20px;
//   width: calc(100% + 40px);
//   .item {
//     background-color: #000;
//     // background-color: #ececec;
//     // margin: 0 0 1px 1px;
//     // padding: 8px;
//     position: relative;
//     &:before {
//       content: "";
//       display: block;
//       padding-top: 100%;
//     }
//     .link {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       // padding: 5px;
//       overflow: hidden;
//       padding: 0;
//       margin: 0;
//     }
//     .title {
//       display: none;
//     }
//     .img {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translateY(-50%) translateX(-50%);
//       -webkit-transform: translateY(-50%) translateX(-50%);
//       // border: 2px solid #fff;
//       border: 1px solid #444;
//       width:auto;
//       height:auto;
//       max-width:100%;
//       max-height:100%;
//     }
//     // .landscape {
//     //   width: 100%;
//     //   height: auto;
//     //   // border-top: 1px solid #444;
//     //   // border-bottom: 1px solid #444;
//     // }
//     // .vertical {
//     //   width: auto;
//     //   height: 100%;
//     //   // border-left: 1px solid #444;
//     //   // border-right: 1px solid #444;
//     // }
//   }
// }
// // マルチプル画像インプットのプレビューフィールド
// .multiple_images_preview {
//   .photo_thumbs_sq {
//     margin-left: 0;
//     width: 100%;
//   }
// }

/**
 * シングルページのヘッダー画像（スライド）
 */
.singleContentHeaderImage {
  .images {
    width: 100%;
    text-align: center;
    height: 100vw;
    max-height: calc(100vh - 70px);
    .image_wrap {
      position: relative;
      height: 100%;
      .photo_by {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #FFF;
        font-size: 1.2rem;
        text-shadow: 0px 0px 5px #000000, 0px 0px 2px #000000;
        z-index: 20;
        a {
          color: #FFF;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .img_block {
      display: block;
      // width: 100%;
      // width: 100%!important; // swiperの幅の計算が少しおかしいので固定。
      height: 100%;
      // overflow: hidden;
      // padding-bottom: 20px;
      position: relative;
      left: 0;
      top: 0;
      overflow: hidden;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        // background-color: rgba(100,0,100,.4);
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;'; /*IE対策*/
      }
      // // IE11では画像がはみ出るのでIE11だけ以下を適用
      // _:-ms-lang(x)::-ms-backdrop, .img {
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   width: auto;
      //   height: auto;
      //   transform: translate3d(-50%, -50%, 0);
      //   min-width: 100%;
      //   min-height: 100%;
      // }
    }

    a.img_block:hover {
      opacity: 1;
    }
  }
}
@include mq(min_tab) { // タブレット
  .singleContentHeaderImage .images {
    min-height: 350px;
    // min-height: calc(100vh - 300px);
    // height: calc(100vh - 500px);
    height: 75vw;
    max-height: calc(100vh - 250px);
  }
}
@include mq(min_m) { // PC
  .singleContentHeaderImage .images {
    min-height: 500px;
    // min-height: calc(100vh - 300px);
    // height: calc(100vh - 500px);
    height: calc((100vw - 280px) * 0.75);
    max-height: calc(100vh - 250px);
  }
}
@include mq(min_3l) { // 大大画面PC
  .singleContentHeaderImage .images {
    min-height: 600px;
    // min-height: calc(100vh - 300px);
    // height: calc(100vh - 500px);
    height: calc(100vh - 300px);
    max-height: 1000px;
  }
}

// スポットシングル
// #spot_images {
//   .images {
//     width: 100%;
//     text-align: center;
//     height: 100vw;
//     max-height: calc(100vh - 70px);
//     .image_wrap {
//       position: relative;
//       height: 100%;
//       .photo_by {
//         position: absolute;
//         right: 10px;
//         bottom: 10px;
//         color: #FFF;
//         font-size: 1.2rem;
//         text-shadow: 0px 0px 5px #000000, 0px 0px 2px #000000;
//         z-index: 20;
//         a {
//           color: #FFF;
//           &:hover {
//             opacity: 1;
//           }
//         }
//       }
//     }
//     .img_block {
//       display: block;
//       // width: 100%;
//       // width: 100%!important; // swiperの幅の計算が少しおかしいので固定。
//       height: 100%;
//       // overflow: hidden;
//       // padding-bottom: 20px;
//       position: relative;
//       left: 0;
//       top: 0;
//       overflow: hidden;
//       &::after {
//         content: '';
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         z-index: 10;
//         left: 0;
//         top: 0;
//         // background-color: rgba(100,0,100,.4);
//       }
//       .img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         font-family: 'object-fit: cover;'; /*IE対策*/
//       }
//       // // IE11では画像がはみ出るのでIE11だけ以下を適用
//       // _:-ms-lang(x)::-ms-backdrop, .img {
//       //   position: absolute;
//       //   top: 50%;
//       //   left: 50%;
//       //   width: auto;
//       //   height: auto;
//       //   transform: translate3d(-50%, -50%, 0);
//       //   min-width: 100%;
//       //   min-height: 100%;
//       // }
//     }

//     a.img_block:hover {
//       opacity: 1;
//     }
//   }
// }

// @include mq(min_tab) { // タブレット
//   #spot_images .images {
//     min-height: 350px;
//     // min-height: calc(100vh - 300px);
//     // height: calc(100vh - 500px);
//     height: 75vw;
//     max-height: calc(100vh - 250px);
//   }
// }
// @include mq(min_m) { // PC
//   #spot_images .images {
//     min-height: 500px;
//     // min-height: calc(100vh - 300px);
//     // height: calc(100vh - 500px);
//     height: calc((100vw - 280px) * 0.75);
//     max-height: 1000px;
//   }
// }
// @include mq(min_3l) { // 大大画面PC
//   #spot_images .images {
//     min-height: 600px;
//     // min-height: calc(100vh - 300px);
//     // height: calc(100vh - 500px);
//     height: calc(100vh - 300px);
//     max-height: 1000px;
//   }
// }
.singleContentHeaderImage {
  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 1;
  }
  .swiper-slide {
    /* margin: 2px 1px!important; */
  }
  // @media (min-width: 800px) {
  //   .swiper-slide a {
  //     background-color: #fff;
  //     .img_block {
  //       transition: all 200ms ease-in-out;
  //     }
  //   }
  //   .swiper-slide a:hover {
  //     .img_block {
  //       opacity: .7;
  //     }
  //   }
  // }
  // .swiper-pagination {
  //   position: absolute;
  //   bottom: 5px!important;
  //   left: 0;
  //   z-index: 120;
  //   opacity: .6;
  //   .swiper-pagination-bullet {
  //     // width: 40px!important;
  //     // height: 5px!important;
  //     width: 10px!important;
  //     height: 10px!important;
  //     margin: 0 3px!important;
  //     // border: 2px solid #fff;
  //     // border: 1px solid #666;
  //     box-shadow: 0px 0px 7px rgba(0,0,0,.3);
  //     display: inline-block!important;
  //     // border-radius: 0!important;
  //     background: #ccc!important;
  //     /* border: 1px solid #fff; */
  //     opacity: 1!important;
  //     line-height: 100%!important;
  //   }
  //   .swiper-pagination-bullet-active {
  //     background: #1E99D5!important;
  //     // border: 2px solid #1E99D5!important;
  //   }
  // }
  .swiper-pagination {
    bottom: 4px!important;
    left: 0;
    z-index: 120;
    opacity: .6;
    font-size: 0;
    .swiper-pagination-bullet {
      width: 25px!important;
      height: 5px!important;
      margin: 0 2px!important;
      box-shadow: 0px 0px 7px rgba(0,0,0,.3);
      display: inline-block!important;
      background: #ccc!important;
      opacity: 1!important;
      line-height: 0!important;
      border-radius: 0!important;
    }
    .swiper-pagination-bullet-active {
      background: #1E99D5!important;
    }
  }
  // .swiper-container-horizontal>.swiper-pagination-bullets {
  //   top: 100%;
  //   bottom: auto;
  //   /* height: 5px; */
  //   /* background: #eee; */
  //   line-height: 100%;
  //   /* padding: 8px 0 0; */
  //   /* border: 1px solid #000; */
  // }
  // .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {

  // }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 20;
    transition: all 200ms ease-in-out;
    width: 40px;
    height: 50px;
    text-indent: -9999em;
    background: none!important;
    margin-top: -25px;
    outline:0;
  }
  // .swiper-button-prev:hover,
  // .swiper-button-next:hover {
  //   background: #000;
  // }
  .swiper-button-prev {
    // left: -42px;
    left: 0px;
  }
  .swiper-button-next {
    // right: -42px;
    right: 0px;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: "";
    position: absolute;
    top: 13px;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .swiper-button-prev::after {
    right: 2px;
    border-bottom: 2px solid #FFF;
    border-left: 2px solid #FFF;
  }
  .swiper-button-next::after {
    left: 2px;
    border-top: 2px solid #FFF;
    border-right: 2px solid #FFF;
  }
  .swiper-button-prev.hover,
  .swiper-button-next.hover {
    /*opacity: .6;*/
    background: rgba(0,0,0,.9);
  }
  // @media (max-width: 500px) {
  //   .swiper-button-prev,
  //   .swiper-button-next {
  //     display: none;
  //   }
  // }
}

// シングルページコンテンツ部共通
h1.title {
  font-size: 2.4rem;
  @include mq(min_tab_l) {
    font-size: 3.5rem;
  }
}
#content .singleContent {
  .cont_header {
    max-width: 800px;
    margin: 4rem auto 6rem;
    padding: 0 15px;
    text-align: center;
    .title {
      display: flex;
      flex-flow: column-reverse wrap;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: 1.5rem;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 6rem;
        height: 2px;
        background-color: #900;
        transform: translateX(-50%);
      }
    }
    .event_date_wrap {
      align-items: center;
    }
  }
  .cont_taxonomy {
    .tags,
    .areas,
    .places,
    .categories,
    .areas {
      display: flex;
      // align-items: center;
      h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
      .icon {
        padding-top: 7px;
        padding-right: 5px;
        width: 35px;
      }
      .links {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: center;
        margin: .7rem .5rem;
        padding-left: 0;
        list-style-type: none;
        li {
          // background-color: #eee;
          // border: 1px solid #ccc;
          // border-radius: .4rem;
          padding: 0;
          margin: .3rem .2rem;
          display: flex;
          .name {
            border: 1px solid #aaa;
            border-radius: 30px;
            color: #000;
            background-color: #e6e6e6;
            text-decoration: none;
            font-size: 1.4rem;
            padding: 0 .8rem;
            &:hover {
              text-decoration: none;
              color: #fff;
              background-color: $link_color;
            }
          }
        }
      }
    }
    .places {
      .place_links {
        flex-flow: column nowrap;
        align-items: stretch;
        // margin: 0;
        margin: .3rem 0;
        padding-left: 0.2rem;
        li {
          margin: 0 .2rem;
          padding-left: 0;
          display: flex;
          .place_title {
            display: block;
            // font-weight: bold;
            margin: 0 .5rem;
          }
        }
        &>li {
          margin-top: .7rem;
          margin-bottom: .7rem;
        }
        .links {
          margin: 0;
        }
      }
    }
    // .tags {
    //   ul {
    //     margin: 1rem 0 .5rem;
    //   }
    // }
  }
  .body {
    //
  }
  .detail {
    font-size: 1.4rem;
    @include mq(min_tab) {
      font-size: 1.6rem;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #ccc;
      border-top: none;
      dt {
        background: #eee;
        // width: 200px;
        width: 20%;
        padding: 10px;
        margin: 0;
        box-sizing: border-box;
        border-top: 1px solid #ccc;
        line-height: 1.5;
      }
      dd {
        padding: 10px;
        margin: 0;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        // width: calc(100% - 200px);
        width: 80%;
        background: #fff;
        box-sizing: border-box;
        line-height: 1.5;
      }
    }
  }
}

// スポット シングル
#spot_cont {
  .cont_header {
    .title {
      .spot_name {
      }
      .spot_name_yomi {
        font-size: 1.4rem;
        @include mq(min_tab) {
          font-size: 1.8rem;
        }
        margin-bottom: .5rem;
        color: #666;
      }
    }
    .places {
      ul {
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        li {
          background-color: #eee;
          margin: .3rem;
          .place_name {
            padding: .2rem .5rem;
          }
        }
      }
    }
  }
}
// img {
//   width: 100%;
// }
// @include mq(min_tab) { // タブレット
//   width: 250px;
// }
// @include mq(min_s) { // PC小
//   width: 300px;
// }
// @include mq(min_3l) { // 大大画面PC
//   width: 100%;
//   max-width: unset;
//   text-align: right;
//   padding: 0 1rem;
//   img {
//     max-width: 350px;
//   }
// }

// 共通一覧ページ
#content .node-list {
  display: flex;
  flex-flow: row wrap;
  // margin-left: 15px;
  .node-list-item {
    // border-right: 1px solid #e0e0e0;
    // border-bottom: 1px solid #e0e0e0;
    border: 1px solid #e0e0e0;
    border: 1px solid #cfcfcf;
    margin: 0 0 15px 15px;
    padding: 0;
    // flex-basis: calc(100% / 3.001 - 1px);
    flex-basis: calc((100% - 44px) / 2.001);
    position: relative;
    // border-radius: 10px;
    // overflow: hidden;
    .icon_pr {
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      background: rgba(#fff,.6);
      font-size: 1.2rem;
      border:1px solid #000;
      border-radius: .3rem;
      color: #000;
      line-height: 1;
      padding: .1rem .2rem;
      z-index: 1;
    }
    .author-link {
      position: absolute;
      top: 0px;
      left: 0px;
      background: rgba(#000,0.8);
      font-size: 1.2rem;
      // display: none;
      .link {
        display: inline-block;
        padding: 3px 5px;
        background: none;
        color: #fff;
      }
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column-reverse nowrap;
      justify-content: flex-end;
      background-color: #fff;
      transition: all 0.3s 0s ease;
      &:hover {
        background-color: #fcfcfc;
        text-decoration: none;
        opacity: .8;
      }
    }
    .img {
      position: relative;
      background-color: #eee;
      &:before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        // padding-top: 75%;
        padding-top: 61.8%;
        width: 0;
        height: 0;
      }
      .image-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;'; /*IE対策*/
        }
      }
    }
    .txt {
      padding: 0 0 .5rem;
      margin: 0;
      color: #000;
      .title {
        margin: 1rem .5rem;
        font-size: 1.6rem;
        @include mq(min_tab) { // タブレット}
          font-size: 1.8rem;
        }
      }
      .excerpt {
        display: none;
        margin: 1rem .5rem;
        font-size: 1.4rem;
        color: #666;
        @include mq(min_tab) { // タブレット}
          display: block;
        }
      }
    }
    @include mq(min_tab_l) { // タブレット
      flex-basis: calc((100% - 60px) / 3.001);
    }
  }

  // 公開状態別設定
  .status-1 { // 公開
  }
  .status-5 { // 限定公開
  }
  .status-10 { // 予約
  }
  .status-20 { // 下書き
    opacity: .6;
    .link {
      background-color: #ffaa00
    }
  }
  .status-40 { // 承認申請中
    .link {
      background-color: #ffaa00
    }
  }
  .status-45 { // 承認却下
    opacity: .3;
  }
  .status-60 { // 公開制限中
    opacity: .6;
    .link {
      background-color: #990000
    }
  }
  .status-90 { // 非公開
    opacity: .6;
    .link {
      background-color: #ff6666
    }
  }
  .photo_thumbs {
    display: none;
  }
}

// 検索フォーム
.search_form {
  // border: 1px solid #eee;
  // background-color: #eee;
  border-top: solid 1px #DFD9D0;
  border-bottom: solid 1px #DFD9D0;
  background-color: #F9F8F7;
  padding: 1rem;
  .search_form_title {
    font-size: 2rem;
    margin: 1rem 1rem 0;
  }
  .search_form_detail {
    margin-bottom: 1rem;
  }
  .search_form_keyword_title {
    font-size: 1.8rem;
    margin: 1rem 0 .3rem;
  }
  .search_form_category_title {
    font-size: 1.8rem;
    margin: 1rem 0 .3rem;
  }
  .search_form_category_subtitle {
    font-size: 1.6rem;
    margin: .7rem 0 .3rem;
  }
  .search_form_category_subtitle.first-of-type {
    margin-top: 0;
  }
  .search_form_date .date {
    display: flex;
    align-items: center;
    @include mq(max_mob_l) {
      display: block;
    }
    span {
      display: flex;
      align-items: center;
      .date_year input {
        width: 5em;
      }
      .date_month input, .date_day input {
        width: 4em;
      }
      label {
        margin-bottom: .25em;
      }
    }
  }
  .category_wrap {
    border: 1px solid #ccc;
    // background-color: #fff;
    border-radius: 4px;
    padding: .5rem 1rem 1rem;
  }
  .category_checkboxies {
    width: 100%;
    padding: 0.7em 0.6em 0.5rem;
    margin: .2rem 0 0.25em;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    label {
      display: inline-block;
      padding: .1rem 0 .3rem;
      margin: 0.1rem 1rem 0.3rem 0.2rem;
      input {
        margin-right: .3rem;
      }
    }
  }
}

// 'max_mob_l': 'screen and (max-width: 599px)',
// 'min_tab_l': 'screen and (min-width: 600px)',

// @include mq(min_tab_l) { // タブレット}
// font-size: 1.2rem;
// }

// イベント一覧
// イベント日程共通
.event_date_wrap {
  margin-top: 1rem;
  display: flex;
  flex-flow: column-reverse wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.6rem;
  font-feature-settings: "palt";
  @include mq(min_tab_l) { // タブレット
    flex-flow: row wrap;
    align-items: center;
  }
  .date {
    display: flex;
    flex-flow: row wrap;
  }
  /*- あと○日など */
  .eventStatus {
    color: #FFF;
    font-size: 1.2rem;
    padding: 3px 5px 1px;
    margin-top: 3px;
    margin-right: 7px;
    text-align: center;
    border-radius: 3px;
    // align-self: center;
    @include mq(min_tab_l) { // タブレット
      font-size: 1.3rem;
    }
  }
  .d0 { background-color: #990000; }
  .d1 { background-color: #E38C8C; }
  .d2 { background-color: #E38C8C; }
  .d3 { background-color: #7BAED5; }
  .d4 { background-color: #CCC; }
  .year > span {
    font-size: 1.4rem;
    @include mq(min_tab_l) { // タブレット
      font-size: 1.8rem;
    }
  }
  .month {
    margin-left: 2px;
    & > span {
      font-weight: bold;
      font-size: 2rem;
      vertical-align: -.1rem;
      @include mq(min_tab_l) { // タブレット
        font-size: 2.4rem;
      }
    }
  }
  .day {
    margin-left: 2px;
    & > span {
      font-weight: bold;
      font-size: 2rem;
      vertical-align: -.1rem;
      @include mq(min_tab_l) { // タブレット
        font-size: 2.4rem;
      }
    }
  }
  .week {
    margin-left: 2px;
    font-weight: bold;
    // font-size: 1.5rem;
    font-size: 1.4rem;
    @include mq(min_tab_l) { // タブレット
      font-size: 1.8rem;
    }
    .wd6 {
      color: #1E99D5;
    }
    .wd0 {
      color: #d51e1e;
    }
  }
  .date .start, .date .end {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: baseline;
    // padding-bottom: 2px;
  }
  .date_uncertain {
    margin-bottom: .8rem;
    font-size: 1.2rem;
    color: #900;
    font-feature-settings: "palt";
    margin: 0 0 0 .5rem;
    padding: 0;
  }
}
.event-list-item.uncertain .event_date_wrap {
  .date,
  .eventStatus {
    opacity: .4;
  }
}

.event-list-header {
  width: 100%;
  .event-nav {
    padding: 14px;
    margin: 0 -14px;
    background-color: rgba(255, 255, 255, .9);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    .event_date_wrap {
      margin-top: 0;
      align-items: baseline;
      &:hover {
        text-decoration: none;
        color: #900;
      }
    }
    .prv_btn,
    .nxt_btn {
      .event_date_wrap {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        line-height: 1.1;
        @include mq(max_mob_l) {
          flex-flow: column nowrap;
          align-items: center;
          .year {
            font-size: 1.1rem;
            span {
              font-size: 1.3rem;
            }
          }
          .month {
            font-size: 1.1rem;
            span {
              font-size: 2.2rem;
            }
          }
        }
      }
    }
    .prv_btn .event_date_wrap {
      padding-left: 2rem;
      &::before {
        content: "";
        width: 11px;
        height: 11px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        left: .5rem;
        margin-top: -5px;
        padding: 0;
      }
    }
    .nxt_btn .event_date_wrap {
      padding-right: 2rem;
      &::after {
        content: "";
        width: 11px;
        height: 11px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(-135deg);
        position: absolute;
        top: 50%;
        right: .5rem;
        margin-top: -5px;
        padding: 0;
      }
    }
  }
  .fix-event-nav {
    position: fixed;
    z-index: 10;
    top: 100px;
    display:  none;
    &.show {
      width: 100%;
      display: flex;
    }
  }
}
#hide-past-event {
  display: none;
  &:not(:checked) {
    &+ .node-list {
      .show-past-event-btn {
        display: none;
      }
      .hide-past-event-btn {
        background-color: #900;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  &:checked {
    &+ .node-list {
      .show-past-event-btn {
        background-color: #1E99D5;
        color: #fff;
        cursor: pointer;
      }
      .hide-past-event-btn {
        display: none;
      }
      .past-event {
        display: none;
      }
    }
  }
}
#content .event-list-item {
  width: 100%;
  border-top: 1px solid #cfcfcf;
  margin: 0;
  position: relative;
  transition: all 500ms 0s ease;
  &:last-child {
    border-bottom: 1px solid #cfcfcf;
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: flex-end;
    background-color: #fff;
    color: #000;
    &:hover {
      background-color: #fcfcfc;
      text-decoration: none;
      color: #000;
    }
  }
  .img {
    position: relative;
    background-color: #eee;
    width: 100px;
    @include mq(min_tab_l) { // タブレット
      width: 200px;
    }
    &:before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      // padding-top: 75%;
      padding-top: 61.8%;
      width: 0;
      height: 0;
    }
    .image-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;'; /*IE対策*/
      }
    }
  }
  .txt {
    width: calc(100% - 100px);
    @include mq(min_tab_l) { // タブレット
      width: calc(100% - 200px);
    }
    padding: 15px 15px;
    .title {
      margin: 0;
      font-size: 1.6rem;
      @include mq(min_tab_l) { // タブレット
        font-size: 2rem;
      }
    }
  }
  .venue {
    margin-top: 1rem;
    font-size: 1.4rem;
    @include mq(min_tab_l) { // タブレット
      font-size: 1.6rem;
    }
  }
  .icon_pr {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    background: rgba(#fff,.6);
    font-size: 1.2rem;
    border:1px solid #000;
    border-radius: .3rem;
    color: #000;
    line-height: 1;
    padding: .1rem .2rem;
    z-index: 1;
  }
  .author-link {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(#000,0.8);
    font-size: 1.2rem;
    // display: none;
    .link {
      display: inline-block;
      padding: 3px 5px;
      background: none;
      color: #fff;
    }
  }
}

// イベントシングル
// ヘッダーの日程
.singleContent .cont_header .event_date_wrap {
  justify-content: center;
}
// 詳細情報の表の日程
.singleContent .detail {
  .event_date_wrap {
    margin-top: -.7rem;
    font-feature-settings: "palt";
  }
  .date_uncertain {
    margin-bottom: .7rem;
    font-size: 1.2rem;
    color: #900;
    font-feature-settings: "palt";
  }
  .date_exception {
    background-color: #fcfcfc;
    border: 1px solid #eee;
    padding: .5rem .7rem;
    margin-bottom: .7rem;
    .date_exception_h {
      font-size: 1.4rem;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding-left: 1rem;
      li {
        font-size: 1.4rem;
        margin: 0;
        padding: 0;
      }
    }
  }
}

// コース一覧
// .plan-node-list {
  .itinerary_index ol {
    border-top: 1px solid #ccc;
    display: flex;
    flex-flow: row wrap;
    // justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: .8rem;
    li {
      font-size: 1.1rem;
      @include mq(min_tab) {
        font-size: 1.2rem;
      }
      padding: 0;
      margin: 0 0 3px 0;
      position: relative;
      .name {
        border: 1px solid #aaa;
        border-radius: 3px;
        color: #000;
        background-color: #e6e6e6;
        text-decoration: none;
        padding: 1px 3px;
      }
      &:nth-child(n + 2) {
        padding-left: .8rem;
        &::before {
          content: '';
          position: absolute;
          top: .65rem;
          left: .15rem;
          width: .4rem;
          height: .4rem;
          border-top: .15rem solid #000;
          border-right: .15rem solid #000;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
  .singleContent .itinerary_index ol {
    border-top: none;
    li {
      font-size: 1.4rem;
    }
  }
// }

#plan_cont {
  // .itinerary {
  //   display: flex;
  //   flex-direction: row-reverse;
  //   justify-content: space-between;
  //   margin: 1rem 0;
  //   .text {
  //     flex-basis: calc(70% - 10px);
  //     .name {
  //       margin-top: 1rem;
  //     }
  //   }
  //   .image {
  //     flex-shrink: 1;
  //     flex-basis: calc(30% - 10px);
  //   }
  // }
  .itinerary {
    border: 1px solid #aaa;
    background-color: #e6e6e6;
    border-radius: 1rem;
    padding: 2rem;
    .name {
      margin: 0 0 2rem;
      padding: 0.2rem 0 1.5rem;
      border-bottom: 1px solid #aaa;
    }
  }
  .traffic {
    margin: 0 0 0 3rem;
    border-left: 2rem solid #aaa;
    padding: 1rem 0 1rem 1rem;
    // background-color: #e6e6e6;
    p {
      // margin-left: -4.5rem;
      // margin-left: -2.5rem;
      margin: 1rem 0 1rem -4.6rem;
      display: flex;
      flex-flow: row nowrap;
      align-content: center;
      align-items: center;
      .traffic_type {
        padding: .4rem;
        border: 2px solid #aaa;
        background-color: #FFF;
        border-radius: 40px;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .traffic_body {
        margin-left: 0;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        font-feature-settings: "palt";
        line-height: 1.3;
        .traffic_title {
          font-weight: bold;
          // border: 1px solid #aaa;
          margin-left: .7rem;
          padding: 0;
        }
        .traffic_text {
          margin-left: .7rem;
          // background-color: #FCC;
        }
      }
    }
  }
}

// ユーザー一覧
.user-list {
  display: flex;
  flex-flow: column nowrap;
  .user_account {
    margin: 0 0 1rem;
  }
}
